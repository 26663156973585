import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  ReactElement,
} from 'react';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RestartIcon from '@material-ui/icons/RefreshOutlined';
import LensIcon from '@material-ui/icons/Lens';
import CommentIcon from '@material-ui/icons/ModeComment';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';
import CheckMark from './custom-icons/check-mark1.svg';
import CheckedMark from './custom-icons/check-mark-single1.svg';
import CheckedAll from './custom-icons/check-mark-all4.svg';
import Liquid from './custom-icons/liquid2.svg';
import Fixed from './custom-icons/fixed2.svg';
import None from './custom-icons/none2.svg';
import History from './custom-icons/history.svg';
import HistoryDisabled from './custom-icons/history-disabled.svg';
import TableValues from '../../../../containers/ValuesTable';
import Dialog from '../../../../containers/Dialog';
import { SelectionProfileForm } from '../../../SelectionProfile';
import styles from './styles';
import {
  AssetTableColumnType,
  AssetTableType,
  AssetTableRowSecurityType,
  AssetTableRowType,
  AssetTableRowValueType,
  UserProfileType,
  MutateAssetValueType,
  MutateAssetValueCommentType,
  MutateAssetValueExtraType,
} from '../../../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface ValueCreateObj {
  year: number;
  month: number;
  value: number;
  refAssetClassId?: number;
  refAccountId: number;
  type: string;
}

interface ValueRemoveObj {
  year: number;
  month: number;
  refAssetClassId?: number;
  refAccountSecurityId?: number;
  refAccountId: number;
  type: string;
}

interface ExtraCreateObj {
  year: number;
  month: number;
  columnType: string;
  value?: number;
  refAccountId: number;
}

interface CommentObj {
  comment: string;
  year: number;
  month: number;
  type?: string;
  refAssetSecurityValueId?: number;
  refAssetAccountValueId?: number;
  [key: string]: any;
}

interface Props {
  history?: any;
  assetTable?: AssetTableType;
  year: number;
  month: number;
  getTable?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<AssetTableType>;
  createValue?: (obj: MutateAssetValueType) => Promise<any>;
  deleteValue?: (obj: MutateAssetValueType) => Promise<any>;
  updateValue?: (obj: MutateAssetValueType) => Promise<any>;
  createExtra?: (obj: MutateAssetValueExtraType) => Promise<any>;
  updateExtra?: (obj: MutateAssetValueExtraType) => Promise<any>;
  deleteExtra?: (obj: MutateAssetValueExtraType) => Promise<any>;
  updateComment?: (obj: MutateAssetValueCommentType) => Promise<any>;
  createComment?: (obj: MutateAssetValueCommentType) => Promise<any>;
  clientId: number;
  activateSelection?: (status: boolean) => void;
  activateHistory?: (status: boolean) => void;
  readOnly?: boolean;
  parentReference: any;
  getNode?: (ref: any, name: string, id: number) => void;
  cardId?: number;
  profile?: UserProfileType;
  selectedRows?: Array<number>;
  selectedColumns?: Array<number>;
  selectedHistory?: Array<number>;
  historyActive?: boolean;
  setSelectedColumns?: (id: number, columns: Array<number>) => void;
  setSelectedRows?: (id: number, rows: Array<number>) => void;
  setSelectedHistory?: (id: number, rows: Array<number>) => void;
  setActiveHistory?: (id: number, active: boolean) => void;
  selectedDate?: any;
  expandedRows?: Array<number>;
  setExpandedRows?: (id: number, rows: Array<any>) => void;
  hasSetSelectedRows?: boolean;
  loading?: boolean;
  showNotification: () => void;
  rootClass?: any;
  root1Class?: any;
  showSelectionProfileNotification?: () => void;
}

const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const AssetTable = (props: Props): ReactElement => {
  const {
    assetTable,
    expandedRows,
    month,
    year,
    getTable,
    clientId,
    readOnly,
    selectedRows,
    selectedColumns,
    setSelectedRows,
    setSelectedHistory,
    setActiveHistory,
    hasSetSelectedRows,
    showNotification,
    activateSelection,
    activateHistory,
    historyActive,
    profile,
    getNode,
    cardId,
    selectedHistory,
    setSelectedColumns,
    setExpandedRows,
    createValue,
    updateValue,
    createExtra,
    updateExtra,
    history,
    updateComment,
    createComment,
    deleteValue,
    deleteExtra,
    parentReference,
    loading,
    selectedDate,
    rootClass,
    root1Class,
    showSelectionProfileNotification,
  } = props;

  const [openComment, setOpenComment] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [totalRow, setTotalRow] = useState<any>(null);
  const [comment, setComment] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedColumn, setSelectedColumn] = useState<string | any>('');
  const [fixedLiquidButton, setFixedLiquidButton] = useState<string>('');
  const [selectedAccountCodes, setSelectedAccountCodes] = useState<any>([]);
  const [role, setRole] = useState<string>('');
  const [focusOnTable, setFocusOnTable] = useState<boolean>(true);
  const [openSelectionProfileForm, setOpenSelectionProfileForm] = useState<
    boolean
  >(false);
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const isComponentMounted = useRef(false);
  useLayoutEffect(() => {});
  const prevRows = usePrevious(tableRows);
  const prevAccountCodes = usePrevious(selectedAccountCodes);

  const updateSelectionStatus = (newInputRows?: Array<any>): void => {
    const currentRows = newInputRows || tableRows;
    if (
      activateSelection &&
      historyActive === false &&
      (currentRows.find(
        (each: any): any => each.rangePosition === 0 && each.enabled === false
      ) ||
        tableColumns.find((each: any): any => each.disabled === true))
    ) {
      activateSelection(true);
    } else if (
      activateSelection &&
      historyActive === true &&
      (currentRows.find((each: any): any => each.enabled === false) ||
        tableColumns.find((each: any): any => each.disabled === true))
    ) {
      activateSelection(true);
    } else if (activateSelection) activateSelection(false);

    if (
      currentRows.find(
        (each: any): any => each.enabled === true && each.rangePosition !== 0
      ) &&
      activateHistory
    ) {
      activateHistory(true);
    } else if (activateHistory) activateHistory(false);
    const newRows = currentRows.map((r: any): any => {
      if (r.rangePosition && r.rangePosition !== 0) {
        if (!historyActive) {
          return { ...r, hiddenRow: true };
        }
        return { ...r, hiddenRow: false };
      }
      return r;
    });
    setTableRows(newRows);
  };

  const handleCheckbox = (
    value: boolean,
    name: string,
    inputAccountCodes: Array<any>
  ): void => {
    // console.log('gg');
    // console.log(tableColumns);
    // const detailColumns = tableColumns.filter(
    //   (col: any): any =>
    //     col.id === 'accountCode' ||
    //     col.id === 'accountNumber' ||
    //     col.id === 'accountType'
    // );
    // console.log('==========');
    let newAccountCodes: any[] = [];
    if (name === 'Select All') {
      newAccountCodes = inputAccountCodes.map((each: any): any => ({
        ...each,
        selected: value,
      }));
    } else {
      newAccountCodes = inputAccountCodes.map((each: any): any => {
        if (each.name === name) return { ...each, selected: !each.selected };
        return each;
      });
    }
    // if (fixedLiquidButton === 'liquid') {
    //   const newRows = tableRows.map((r: any): any => {

    //       if (
    //         newAccountCodes.find(
    //           (each: any): any => each.name === r.accountCode
    //         ) &&
    //         newAccountCodes.find(
    //           (each: any): any => each.name === r.accountCode
    //         ).selected
    //       ) {
    //         return { ...r, enabled: true };
    //       }
    //       return { ...r, enabled: false };

    //   });
    //   const enabledRows = newRows
    //     .filter((r: any): any => r.enabled === true)
    //     .map((r: any): number => r.accountId);

    //   // setTableRows(newRows);
    //   setSelectedAccountCodes(newAccountCodes);
    //   // eslint-disable-next-line
    //   //setColumns(detailColumns, newAccountCodes);
    //   if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    // } else if (fixedLiquidButton === 'fixed') {
    //   const newRows = tableRows.map((r: any): any => {
    //     if (r.assetType !== 'FIXED') return { ...r, enabled: false };
    //     if (r.assetType === 'FIXED') {
    //       if (
    //         newAccountCodes.find(
    //           (each: any): any => each.name === r.accountCode
    //         ) &&
    //         newAccountCodes.find(
    //           (each: any): any => each.name === r.accountCode
    //         ).selected
    //       ) {
    //         return { ...r, enabled: true };
    //       }
    //       return { ...r, enabled: false };
    //     }
    //     return r;
    //   });
    //   const enabledRows = newRows
    //     .filter((r: any): any => r.enabled === true)
    //     .map((r: any): number => r.accountId);
    //   // setTableRows(newRows);
    //   setSelectedAccountCodes(newAccountCodes);
    //   // eslint-disable-next-line
    //   //setColumns(detailColumns, newAccountCodes);
    //   if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    // } else {
    const newRows = tableRows
      .filter((r: any): any => !r.isSecurity)
      .map((r: any): any => {
        if (
          newAccountCodes.find(
            (each: any): any => each.name === r.accountCode
          ) &&
          newAccountCodes.find((each: any): any => each.name === r.accountCode)
            .selected
        )
          return { ...r, enabled: true };
        return { ...r, enabled: false };
      });
    const enabledRows = newRows
      .filter((r: any): any => r.enabled === true)
      .map((r: any): number => r.accountId);
    // setTableRows(newRows);
    setSelectedAccountCodes(newAccountCodes);
    // eslint-disable-next-line
      //setColumns(detailColumns, newAccountCodes);
    if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    if (fixedLiquidButton !== 'none') setFixedLiquidButton('none');
    // }
  };

  const setColumns = (
    detailColumns: any,
    newAccountCodes: any,
    aTable?: AssetTableType
  ): void => {
    const { columns } = aTable || assetTable || { columns: [] };
    const newTotalRow: { [key: string]: any } = { assetManager: 'Total' };
    let newColumns: any[] = [];
    if (aTable || assetTable) {
      newColumns = [
        {
          title: 'Asset Manager',
          name: 'assetManager',
          type: 'header',
          columnId: 'assetManager-Asset Manager',
        },
        {
          title: 'Account Code',
          name: 'accountCode',
          columnId: 'accountCode-Account Code',
          type: 'readOnly',
          hidden: !(
            detailColumns &&
            detailColumns.find(
              (col: any): any =>
                col.id === 'accountCode' && col.hidden === false
            )
          ),
          id: 'accountCode',
          hasDetails: true,
          detailsTitle: 'Select Code',
          details: newAccountCodes
            ? newAccountCodes.map((each: any): any => ({
                name: each.name,
                onClick: (checked: boolean, name: string): void =>
                  handleCheckbox(checked, name, newAccountCodes),
                notCheckedIcon: (
                  <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />
                ),
                checkedIcon: <CheckBoxIcon style={{ fontSize: 20 }} />,
                selected: each.selected,
              }))
            : [],
          style: { textAlign: 'Left', paddingLeft: '6px' },
        },
        {
          title: 'Account Type',
          name: 'accountType',
          columnId: 'accountType-Account Type',
          type: 'readOnly',
          hidden: !(
            detailColumns &&
            detailColumns.find(
              (col: any): any =>
                col.id === 'accountType' && col.hidden === false
            )
          ),
          id: 'accountType',
          style: { textAlign: 'Left', paddingLeft: '6px' },
        },
        {
          title: 'Account Number',
          name: 'accountNumber',
          columnId: 'accountNumber-Account Number',
          type: 'readOnly',
          hidden: !(
            detailColumns &&
            detailColumns.find(
              (col: any): any =>
                col.id === 'accountNumber' && col.hidden === false
            )
          ),
          id: 'accountNumber',
          style: { textAlign: 'Left', paddingLeft: '6px' },
        },
      ];
      columns.forEach((col: AssetTableColumnType): void => {
        if (col.columnHeading === 'CASH IN/OUT') {
          newColumns.push(
            { title: 'Total', name: 'total', columnId: 'total-Total' },
            {
              title: col.columnHeading,
              name: `${col.type}`,
              columnId: `${col.type}-${col.columnHeading}`,
              dontShowZero: true,
              style: {
                background: '#039be5',
                border: '1px solid rgba(25, 118 , 210, 0.5)',
                color: 'rgb(0,0,0, 0.7)',
              },
            }
          );
        } else {
          newColumns.push({
            title: col.columnHeading,
            name: `${col.id}`,
            columnId: `${col.id}-${col.columnHeading}`,
            id: col.id,
            description: col.description,
            style: col.disabled ? { color: 'rgb(0, 0 , 0, 0.4)' } : {},
            disabled: col.disabled,
            canDisable: true,
            hoveredComponent: (
              <div className={classes.popLabel}>
                <strong>Description:</strong> {col.description}
              </div>
            ),
          });
        }
        if (col.type === 'CASH') {
          newTotalRow[col.type] = col.total;
          newTotalRow[`${col.type}.style`] = {
            background: '#e9f8fe',
            backgroundClip: 'padding-box',
          };
        } else {
          newTotalRow[col.id] = col.total;
          newTotalRow[`${col.id}.disabled`] = col.disabled;
          newTotalRow[`${col.id}.disabled.style`] = col.disabled
            ? { color: 'rgb(0, 0 , 0, 0.4)' }
            : {};
        }
      });
      newTotalRow.total = aTable ? aTable?.total : assetTable?.total;
    }
    setTableColumns(newColumns);
    setTotalRow(newTotalRow);
  };

  const setRows = (
    openRows: any,
    selectedCodes?: any,
    detailColumns?: any,
    aTable?: AssetTableType
  ): void => {
    const { rows } = aTable || assetTable || { rows: [] };
    const newRows: any[] = [];
    const accountCodes: any[] = [];
    rows.forEach((r: AssetTableRowType, idx: number): void => {
      const newRow: { [key: string]: any } = {
        id: `${r.assetManager}-${idx}`,
        assetManager: r.assetManager,
        assetManager1: r.assetManager,
        accountCode: r.accountCode,
        accountType: r.accountType,
        accountNumber: r.number,
        assetType: r.assetType,
        accountId: r.accountId,
        total: r.total,
        'total.notEditable': true,
        type: r.type,
        enabled: r.enabled,
        rangePosition: r.rangePosition,
        hasSecurity: !!r.securities,
        securities: r.securities,
        details: r.securities,
        hasDetails: !!r.securities,
        rowType: r.securities || r.inferred ? 'readOnly' : '',
        isExpanded: !!(
          openRows &&
          openRows.find((or: any): any => or.id === `${r.assetManager}-${idx}`)
        ),
        hidden: false,
        hiddenRow: false,
        hasSecurityWeights: r.hasSecurityWeights,
        hasTotalWithNoValue:
          r.securities &&
          r.securities.find(
            (rs: AssetTableRowSecurityType): any => !rs.hasSecurityWeights
          ),
        PopperDetails: (
          <Paper
            style={{
              padding: '5px',
              fontSize: '12px',
            }}
          >
            <div className={classes.popLabel}>
              <strong>Asset Manager:</strong> {r.assetManager}
            </div>
            <div className={classes.popLabel}>
              <strong>Account Code:</strong> {r.accountCode}
            </div>
            <div className={classes.popLabel}>
              <strong>Account Type:</strong> {r.accountType}
            </div>
            <div className={classes.popLabel}>
              <strong>Account Number:</strong> {r.accountNumber}
            </div>
          </Paper>
        ),
      };

      if (r.rangePosition !== 0 && !historyActive) {
        newRow.hiddenRow = true;
      }

      if (
        !accountCodes.find((each: any): any => each.name === newRow.accountCode)
      ) {
        accountCodes.push({
          name: newRow.accountCode,
          selected:
            selectedCodes &&
            Array.isArray(selectedCodes) &&
            selectedCodes.find(
              (each: any): any => each.name === newRow.accountCode
            )
              ? selectedCodes.find(
                  (each: any): any => each.name === newRow.accountCode
                ).selected
              : true,
        });
      }
      r.values.forEach((value: AssetTableRowValueType): void => {
        newRow[value.refAssetClassId] = value.value;
        newRow[`${value.refAssetClassId}.status`] = value.status;
        newRow[`${value.refAssetClassId}.hasExtraOption`] =
          value.status === 'NEW';
        newRow[`${value.refAssetClassId}.refAssetAccountValueId`] =
          value.refAssetAccountValueId || '';
        if (
          !(
            (value.value === 0 && value.status === 'HISTORICAL') ||
            !!r.securities
          )
        ) {
          newRow[`${value.refAssetClassId}.isEditable`] = true;
          newRow[`${value.refAssetClassId}.editable`] = true;
        }
        if (value.comment) {
          newRow[`${value.refAssetClassId}.detail`] = value.comment;
          newRow[`${value.refAssetClassId}.hasDetail`] = true;
        }
        if (value.status === 'NEW') {
          newRow[`${value.refAssetClassId}.style`] = {
            fontWeight: 800,
          };
        }
      });
      newRow[`CASH.style`] = {
        background: '#e9f8fe',
        backgroundClip: 'padding-box',
      };
      newRow['CASH.editable'] = true;
      if (r.extraValues && r.extraValues.length > 0) {
        newRow.hasExtraValues = true;
        newRow[`${r.extraValues[0].type}`] = r.extraValues[0].value;
      }
      if (r.rangePosition !== 0) {
        const transparency = r.rangePosition > 0 ? 0.3 : 0.6;
        newRow.style = {
          backgroundColor: `rgb(192,192,192,${transparency})`,
        };
        newRow.delayStyle = true;
      }
      newRows.push(newRow);
      if (newRow.hasSecurity && r.securities) {
        r.securities.forEach((rs: AssetTableRowSecurityType): void => {
          const newSecurityRow: { [key: string]: any } = {
            id: `${r.assetManager}-${idx}-${rs.name}`,
            assetManager: rs.name,
            total: rs.total,
            hidden: !(
              openRows &&
              openRows.find(
                (or: any): any => or.id === `${r.assetManager}-${idx}`
              )
            ),
            status: rs.status,
            source: newRow.assetManager1,
            sourceId: `${r.assetManager}-${idx}`,
            isSecurity: true,
            isDetail: true,
            refAccountSecurityId: rs.refAccountSecurityId,
            refAssetSecurityValueId: rs.refAssetSecurityValueId,
            refSecurityId: rs.refSecurityId,
            accountId: r.accountId,
            rowType: 'readOnly',
            hasTotalWithNoValue: !rs.hasSecurityWeights,
            style: {
              backgroundColor: 'rgb(255,255,51, 0.3)',
            },
            'total.isEditable': true,
            'total.editable': true,
            'total.hasExtraOption': rs.total && rs.status === 'NEW',
          };
          if (rs.comment) {
            newSecurityRow[`total.detail`] = rs.comment;
            newSecurityRow[`total.hasDetail`] = true;
          }
          newSecurityRow[`CASH.style`] = {
            background: '#e9f8fe',
            backgroundClip: 'padding-box',
          };
          if (rs.values) {
            rs.values.forEach((value: any): void => {
              newSecurityRow[value.refAssetClassId] = value.value;
              newSecurityRow[`${value.refAssetClassId}.status`] = value.status;
              newRow[`${value.refAssetClassId}.refAssetAccountValueId`] =
                value.refAssetAccountValueId || '';
              if (value.comment) {
                newSecurityRow[`${value.refAssetClassId}.detail`] =
                  value.comment;
                newSecurityRow[`${value.refAssetClassId}.hasDetail`] = true;
              }
            });
          }
          newRows.push(newSecurityRow);
        });
      }
    });
    const finalAccountCodes = accountCodes.map((eachAccountCode: any): any => ({
      name: eachAccountCode.name,
      selected: !newRows.find(
        (eachRow: any): any =>
          eachRow.accountCode === eachAccountCode.name && !eachRow.enabled
      ),
    }));
    finalAccountCodes.push({
      name: 'Select All',
      selected: !finalAccountCodes.find((each: any): any => !each.selected),
    });
    setTableRows(newRows);
    setSelectedAccountCodes(finalAccountCodes);
    if (!readOnly) {
      updateSelectionStatus(newRows);
    }
    setColumns(detailColumns, finalAccountCodes, aTable);
    if (readOnly)
      setTimeout(() => {
        if (getNode && cardId) getNode(containerRef, 'VALUES', cardId);
      }, 2000);
  };

  const getTableData = (isMounted?: boolean): void => {
    if (getTable)
      getTable(clientId, year, month, selectedRows || [], selectedColumns || [])
        .then((aTable: AssetTableType): void => {
          if (
            !hasSetSelectedRows &&
            setSelectedRows &&
            setSelectedHistory &&
            setActiveHistory &&
            !readOnly
          ) {
            setSelectedRows(
              clientId,
              (aTable &&
                aTable.rows &&
                aTable.rows.map(
                  (r: AssetTableRowType): number => r.accountId
                )) ||
                []
            );
            setSelectedHistory(
              clientId,
              (aTable &&
                aTable.rows &&
                aTable.rows
                  .filter(
                    (r: AssetTableRowType): any =>
                      r.rangePosition !== 0 && r.enabled
                  )
                  .map((r: AssetTableRowType): number => r.accountId)) ||
                []
            );
            setActiveHistory(clientId, true);
          }
          if (isMounted && !readOnly) {
            setRows(expandedRows, undefined, undefined, aTable);
          }
          if (readOnly) {
            setRows([], undefined, undefined, aTable);
          }
        })
        .catch(() => showNotification && showNotification());
  };

  const setProfileRole = (): void => {
    const newRole =
      profile && profile.roles && profile.roles[0] && profile.roles[0].name;
    if (newRole) setRole(newRole);
  };

  // const getTableAfterChangingDate = (rows: any): void => {
  //   const enableRows = rows
  //     .filter((r: any): any => r.enabled === true)
  //     .map((r: any): number => r.accountId);

  //   const notInTable =
  //     (selectedHistory &&
  //       selectedHistory.filter(
  //         (eachOne: any): any =>
  //           !rows.find((r: any): any => r.accountId === eachOne)
  //       )) ||
  //     [];
  //   if (setSelectedHistory)
  //     setSelectedHistory(clientId, [...notInTable, ...enableRows]);
  //   getTableData();
  // };

  useEffect(() => {
    if (isComponentMounted?.current) {
      getTableData();
    }
  }, [selectedColumns, selectedRows, cardId, selectedDate, month, year]);

  useEffect(() => {
    isComponentMounted.current = true;
    getTableData(true);
    if (!readOnly) {
      setProfileRole();
      if (assetTable) setRows(expandedRows);
    }
    return (): any => {
      isComponentMounted.current = false;
    };
  }, []);

  // useEffect(() => {
  //   if (prevRows) getTableAfterChangingDate(prevRows);
  // }, [selectedDate, month, year]);

  useEffect(() => {
    updateSelectionStatus();
  }, [historyActive]);

  useEffect(() => {
    const detailColumns = tableColumns.filter(
      (col: any): any =>
        col.id === 'accountCode' ||
        col.id === 'accountNumber' ||
        col.id === 'accountType'
    );
    if (assetTable) {
      setRows(expandedRows, prevAccountCodes, detailColumns);
    }
  }, [assetTable]);

  useEffect(() => {
    setProfileRole();
  }, [profile]);

  const handleCheckColumn = (data: string, value: boolean): void => {
    if (data === 'all') {
      const newColumns = tableColumns.map((col: any): any => {
        if (col.canDisable) return { ...col, disabled: !value };
        return col;
      });
      const disabledColumns = newColumns
        .filter((col: any): any => col.disabled === true)
        .map((col: any): number => col.id);
      setTableColumns(newColumns);
      setFixedLiquidButton('none');
      if (setSelectedColumns) setSelectedColumns(clientId, disabledColumns);
    } else if (
      data === 'accountCode' ||
      data === 'accountNumber' ||
      data === 'accountType'
    ) {
      const newColumns = tableColumns.map((each: any): any => {
        if (each.id === data) {
          return { ...each, hidden: !each.hidden };
        }
        return each;
      });
      setTableColumns(newColumns);
    } else {
      const newColumns = tableColumns.map((col: any): any => {
        if (col.id === data) return { ...col, disabled: !value };
        return col;
      });
      const disabledColumns = newColumns
        .filter((col: any): any => col.disabled === true)
        .map((col: any): number => col.id);
      setTableColumns(newColumns);
      setFixedLiquidButton('none');
      if (setSelectedColumns) setSelectedColumns(clientId, disabledColumns);
    }
  };

  const handleCheckRow = (data: string, value: boolean): void => {
    if (data === 'all') {
      const newRows = tableRows.map((r: any): any => {
        if (r.accountId) return { ...r, enabled: value };
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      const newHistory = newRows
        .filter(
          (r: any): any => r.enabled && r.rangePosition !== 0 && !r.isSecurity
        )
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('none');
      if (setSelectedHistory) setSelectedHistory(clientId, newHistory);
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
      if (fixedLiquidButton !== 'none') setFixedLiquidButton('none');
    } else {
      const newRows = tableRows.map((r: any): any => {
        if (r.id === data) return { ...r, enabled: value };
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      const newHistory = newRows
        .filter(
          (r: any): any => r.enabled && r.rangePosition !== 0 && !r.isSecurity
        )
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('none');
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
      if (setSelectedHistory) setSelectedHistory(clientId, newHistory);
      if (fixedLiquidButton !== 'none') setFixedLiquidButton('none');
    }
  };

  const openCommentDialog = (event: any, row: any, col: any): void => {
    setOpenComment(true);
    setComment(row[`${col.name}.detail`]);
    setSelectedRow(row);
    setSelectedColumn(col);
    setFocusOnTable(false);
  };

  const expand = (event: any, row: any): void => {
    const newRows = tableRows.map((r: any): any => {
      if (r.isSecurity && r.sourceId === row.id) {
        return { ...r, hidden: false };
      }
      if (r.id === row.id) {
        return { ...r, isExpanded: true };
      }
      return r;
    });
    setTableRows(newRows);
    if (setExpandedRows)
      setExpandedRows(
        clientId,
        newRows.filter((each: any): any => each.isExpanded === true)
      );
  };

  const unexpand = (event: any, row: any): void => {
    const newRows = tableRows.map((r: any): any => {
      if (r.isSecurity && r.sourceId === row.id) {
        return { ...r, hidden: true };
      }
      if (r.id === row.id) {
        return { ...r, isExpanded: false };
      }
      return r;
    });
    setTableRows(newRows);
    if (setExpandedRows)
      setExpandedRows(
        clientId,
        newRows.filter((each: any): any => each.isExpanded === true)
      );
  };

  const submit = (row: any, name: string, value: string): void => {
    if (role === 'VIEWER') return;
    if (name === 'total') {
      const mainRow = tableRows.find((r: any): any => r.id === row.sourceId);
      const isNew =
        row.total === undefined ||
        row.total === null ||
        (row.status && row.status !== 'NEW');
      const newData = {
        year,
        month,
        value: Number(value),
        refAccountId: mainRow.accountId,
        refAccountSecurityId: row.refAccountSecurityId,
        type: 'SECURITY',
      };
      if (isNew && createValue) {
        createValue(newData).then((): void => getTableData());
      } else if (updateValue)
        updateValue(newData).then((): void => getTableData());
    } else if (name === 'CASH') {
      const isNew = row.hasExtraValues;
      const data = {
        year,
        month,
        columnType: 'ASSETCLASS',
        value: Number(value),
        refAccountId: row.accountId,
      };
      if (!isNew && createExtra) {
        createExtra(data).then((): void => getTableData());
      } else if (updateExtra)
        updateExtra(data).then((): void => getTableData());
    } else {
      const isNew =
        !row[`${name}.status`] || row[`${name}.status`] === 'HISTORICAL';
      const data = {
        year,
        month,
        value: Number(value),
        refAssetClassId: Number(name),
        refAccountId: row.accountId,
        type: 'VALUE',
      };
      if (isNew && createValue) {
        createValue(data).then((): void => getTableData());
      } else if (updateValue)
        updateValue(data).then((): void => getTableData());
    }
  };

  const goToSecurity = (event: any, row: any): void => {
    history.push(`/security/${row.refSecurityId}`, { date: { year, month } });
  };

  const handleLiquidAccounts = (): void => {
    if (fixedLiquidButton === 'none') {
      const newRows = tableRows.map((r: any): any => {
        if (r.assetType !== 'LIQUID') return { ...r, enabled: false };
        if (r.assetType === 'LIQUID') {
          return { ...r, enabled: true };
        }
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('liquid');
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    } else if (fixedLiquidButton === 'liquid') {
      const newRows = tableRows.map((r: any): any => {
        if (r.assetType !== 'FIXED') return { ...r, enabled: false };
        if (r.assetType === 'FIXED') {
          return { ...r, enabled: true };
        }
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('fixed');
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    } else {
      const newRows = tableRows.map((r: any): any => {
        return { ...r, enabled: true };
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('none');
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    }
  };

  const closeCommentDialog = (): void => {
    setOpenComment(false);
    setFocusOnTable(true);
  };

  const submitComment = (): void => {
    const { rows } = assetTable || { rows: [] };
    const stateRow = tableRows.find((sr: any): any => sr.id === selectedRow.id);
    const mainRow = rows.find(
      (r: any): any => r.accountId === stateRow.accountId
    );
    const data: MutateAssetValueCommentType = {
      comment,
      year,
      month,
    };
    let isNotNew;
    switch (stateRow.isSecurity) {
      case true:
        isNotNew =
          mainRow &&
          mainRow.securities.find(
            (s: any): any => s.comment && s.commentStatus === 'NEW'
          );
        data.type = 'SECURITY';
        data.refAssetSecurityValueId = stateRow.refAssetSecurityValueId;
        break;
      case false:
      default:
        isNotNew =
          mainRow &&
          mainRow.values.find(
            (v: any): any =>
              v.refAssetClassId === Number(selectedColumn.name) &&
              v.comment &&
              v.commentStatus === 'NEW'
          );
        data.type = 'VALUE';
        data.refAssetAccountValueId =
          stateRow[`${selectedColumn.name}.refAssetAccountValueId`];
        break;
    }
    closeCommentDialog();
    if (isNotNew && updateComment) {
      updateComment(data).then((): void => getTableData());
    } else if (createComment)
      createComment(data).then((): void => getTableData());
  };

  const deleteValueMethod = (event: any, row: any, col: any): void => {
    if (col.name === 'CASH') {
      const data = {
        year,
        month,
        refAccountId: row.accountId,
        columnType: 'ASSETCLASS',
      };
      if (deleteExtra) deleteExtra(data).then((): void => getTableData());
    } else {
      const data: {
        [key: string]: any;
        year: number;
        month: number;
        refAccountId: number;
        type: string;
      } = {
        year,
        month,
        refAccountId: row.accountId,
        type: 'VALUE',
      };
      if (col.name === 'total') {
        data.refAccountSecurityId = row.refAccountSecurityId;
        data.type = 'SECURITY';
      } else {
        data.refAssetClassId = Number(col.name);
      }
      if (deleteValue) deleteValue(data).then((): void => getTableData());
    }
  };

  const toggleAccountDetails = (): void => {
    const accountCodeColumn = tableColumns.find(
      (col: any): any => col.name === 'accountCode'
    );
    const accountNumberColumn = tableColumns.find(
      (col: any): any => col.name === 'accountNumber'
    );
    const accountTypeColumn = tableColumns.find(
      (col: any): any => col.name === 'accountType'
    );
    const hidden =
      accountCodeColumn.hidden &&
      accountNumberColumn.hidden &&
      accountTypeColumn.hidden;
    const newColumns = tableColumns.map((col: any): any => {
      if (
        col.name === 'accountCode' ||
        col.name === 'accountNumber' ||
        col.name === 'accountType'
      ) {
        return { ...col, hidden: !hidden };
      }
      return col;
    });
    setTableColumns(newColumns);
  };

  const SaveProfileButton = (
    <Button
      onClick={(): void => setOpenSelectionProfileForm(true)}
      variant="outlined"
      className={classes.savePorfileButton}
      classes={{ label: classes.savePorfileButtonLabel }}
    >
      Save Selection
    </Button>
  );

  const handleCloseSelectionProfileForm = (isSubmited?: boolean): void => {
    if (isSubmited) {
      if (showSelectionProfileNotification) showSelectionProfileNotification();
      setOpenSelectionProfileForm(false);
    } else {
      setOpenSelectionProfileForm(false);
    }
  };

  const getHistory = (): any => selectedHistory;

  const handleHistoryButton = (): void => {
    // const inputHistory = getHistory();
    // if (historyActive) {
    //   const newRows =
    //     selectedRows?.filter(
    //       (r: number): any =>
    //         !inputHistory?.find((eachR: any): any => eachR === r)
    //     ) || [];
    //   if (setSelectedRows) setSelectedRows(clientId, newRows);
    //   if (setActiveHistory) setActiveHistory(clientId, !historyActive);
    // } else {
    //   const newRows = selectedRows?.concat(...inputHistory) || [];
    //   if (setSelectedRows) setSelectedRows(clientId, newRows);
    //   if (setActiveHistory) setActiveHistory(clientId, !historyActive);
    // }
    if (setActiveHistory) setActiveHistory(clientId, !historyActive);
  };

  const actions = [
    {
      label: 'cancel',
      onClick: closeCommentDialog,
      type: 'secondary',
    },
    {
      label: 'save',
      onClick: submitComment,
      type: 'primary',
    },
  ];

  let statusIcon = None;
  if (fixedLiquidButton === 'liquid') statusIcon = Liquid;
  if (fixedLiquidButton === 'fixed') statusIcon = Fixed;

  let secondStatusIcon = History;
  if (!historyActive) secondStatusIcon = HistoryDisabled;

  return (
    <div className={classnames(classes.root1, root1Class)}>
      <div
        className={classnames(classes.root, rootClass)}
        ref={readOnly ? containerRef : parentReference}
      >
        <div>
          <div className={classes.topElement} />
          <TableValues
            rows={tableRows}
            columns={tableColumns}
            hasColumnCheckbox
            hasRowCheckbox
            selectOnDoubleClick
            moveBetweenCells
            onHoverColumnHeading
            showEditOnHover
            editOptions={[
              {
                icon: (
                  <CommentIcon
                    style={{
                      fontSize: '14px',
                      color: '#1976d2',
                    }}
                  />
                ),
                onClick: openCommentDialog,
                text: 'Comment',
              },
            ]}
            extraOption={{
              icon: (
                <RestartIcon
                  style={{
                    fontSize: '14px',
                    color: '#1976d2',
                  }}
                />
              ),
              onClick: deleteValueMethod,
              text: 'Reset',
            }}
            cellRootClass={classes.tobe}
            checkboxBlankInputIcon={CheckMark}
            checkedAllIcon={CheckedAll}
            checkedIcon={CheckedMark}
            spaceBeforeTotalRow
            totalRow={totalRow}
            totalCellClass={classes.tobe}
            role={role}
            hasChangeTableStatusButton
            onChangeTableStatus={handleLiquidAccounts}
            changeTableStatusIcon={statusIcon}
            changeStatusTableTitle="Select All-Liquid-Fixed"
            hasSecondChangeTableStatusButton
            onSecondChangeTableStatus={handleHistoryButton}
            secondChangeTableStatusIcon={secondStatusIcon}
            secondChangeStatusTableTitle="Show Historic / Future AM"
            onShrink={unexpand}
            onExpand={expand}
            goToDetail={goToSecurity}
            showPopperForHeaderCell
            cellDetailsIcon={
              <LensIcon style={{ fontSize: '5px', color: '#1976d2' }} />
            }
            cellDetailIconHovered={
              <CommentIcon style={{ fontSize: '10px', color: '#1976d2' }} />
            }
            onSubmit={submit}
            handleCheckRows={handleCheckRow}
            handleCheckColumns={handleCheckColumn}
            focusOnTable={focusOnTable}
            hasExpandColumns
            hasColumnSelection
            onExpandColumns={toggleAccountDetails}
            expandColumnsIcon={None}
            expandColumnsTitle="View Account Code-Type-Number"
            numberPrecision={0}
            stickyHeader
            disableOnLoading
            loading={loading}
            secondTableStatusComponent={SaveProfileButton}
          />
        </div>
        <Dialog
          open={openComment}
          onClose={closeCommentDialog}
          title="Comment"
          actions={actions}
        >
          <div className={classes.formContainer}>
            <TextField
              id="outlined-comment"
              label="Comment"
              multiline
              value={comment}
              onChange={(e: any): void => setComment(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.fullField}
              autoFocus
            />
          </div>
        </Dialog>
      </div>
      {openSelectionProfileForm && (
        <SelectionProfileForm
          open={openSelectionProfileForm}
          onClose={handleCloseSelectionProfileForm}
          clientId={clientId}
        />
      )}
    </div>
  );
};

AssetTable.defaultProps = {
  assetTable: undefined,
  readOnly: false,
  tableEnabledRows: [],
  tableDisabledColumns: [],
  reportObject: undefined,
  setReportJSON: undefined,
  parentReference: undefined,
  getNode: undefined,
  cardId: undefined,
  history: undefined,
  activateSelection: undefined,
  activateHistory: undefined,
  profile: undefined,
  selectedRows: undefined,
  selectedColumns: [],
  selectedHistory: [],
  historyActive: false,
  selectedDate: undefined,
  expandedRows: [],
  hasSetSelectedRows: undefined,
  loading: false,
  showNotification: undefined,
};

export default AssetTable;
